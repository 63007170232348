import React, { useEffect, useState } from 'react';
import fetch from 'src/utils/fetch';
import OriginalWALogo from '../../../public/static/images/svg/OriginalWALogo.svg';

const ButtonWhatsapp = () => {
    const [result, setResult] = useState('');

    useEffect(() => {
        const getChatBot = async () => {
            const { status, data } = await fetch('GET', '/kentico/chatbot', {}, true)
            if (status == 200) {
                setResult(data)
            }
        }
        getChatBot()
    }, [])

    return (
        <React.Fragment>
            {result !== '' ?
                <div className='container'>
                    <a href={result}>
                        <div className='content'>
                            <div className='content-logo'>
                                <img src={OriginalWALogo} />
                            </div>
                            <div className='content-text'>
                                <span className='text'>Chat Lewat Whatsapp</span>
                            </div>
                        </div>
                    </a>
                </div> :
                ''}

            <style jsx>{`
                .container{
                    position: fixed;
                    bottom: 23px;
                    right: 16px;
                }
                .content{
                    display: flex;
                    align-items: center;
                    background-color: #FFFFFF;
                    padding: 8px 8px 3px 8px;
                    box-shadow: 0px 2px 4px rgba(26, 26, 26, 0.2);
                    border-radius: 40px;
                }
                .content-text{
                    margin-bottom: 5px;
                }
                .text{
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 16px;
                    color: #25D366;
                }
                @media only screen and (max-width: 600px) {
                    .container{
                        right: 16px;
                        bottom: 16px;
                    }
                    .content-text{
                        display: none;
                    }
                }
           `}</style>
        </React.Fragment>
    )
};

export default ButtonWhatsapp;